import(/* webpackMode: "eager", webpackExports: ["GlobalContextProvider"] */ "C:\\Users\\klassd\\Documents\\repos\\vdb_pub_dev\\app\\Context\\store.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\Users\\klassd\\Documents\\repos\\vdb_pub_dev\\app\\ErrorBoundary.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\Users\\klassd\\Documents\\repos\\vdb_pub_dev\\app\\search\\[menu].tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\Users\\klassd\\Documents\\repos\\vdb_pub_dev\\components\\_header.tsx");
;
import(/* webpackMode: "eager" */ "C:\\Users\\klassd\\Documents\\repos\\vdb_pub_dev\\components\\_map.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\Users\\klassd\\Documents\\repos\\vdb_pub_dev\\components\\_search.tsx");
;
import(/* webpackMode: "eager" */ "C:\\Users\\klassd\\Documents\\repos\\vdb_pub_dev\\node_modules\\@next\\third-parties\\dist\\google\\ga.js");
;
import(/* webpackMode: "eager" */ "C:\\Users\\klassd\\Documents\\repos\\vdb_pub_dev\\node_modules\\@next\\third-parties\\dist\\google\\gtm.js");
;
import(/* webpackMode: "eager" */ "C:\\Users\\klassd\\Documents\\repos\\vdb_pub_dev\\node_modules\\@next\\third-parties\\dist\\ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "C:\\Users\\klassd\\Documents\\repos\\vdb_pub_dev\\node_modules\\next\\dist\\client\\script.js");
;
import(/* webpackMode: "eager" */ "C:\\Users\\klassd\\Documents\\repos\\vdb_pub_dev\\node_modules\\next\\dist\\shared\\lib\\lazy-dynamic\\dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "C:\\Users\\klassd\\Documents\\repos\\vdb_pub_dev\\node_modules\\next\\dist\\shared\\lib\\lazy-dynamic\\preload-css.js");
