//@ts-nocheck
import Map from "@arcgis/core/Map";
import MapView from "@arcgis/core/views/MapView";
import SpatialReference from "@arcgis/core/geometry/SpatialReference.js";
import Collection from '@arcgis/core/core/Collection';
import Basemap from "@arcgis/core/Basemap";
import TileLayer from "@arcgis/core/layers/TileLayer";
import MapImageLayer from "@arcgis/core/layers/MapImageLayer.js";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer.js";
import * as urlUtils from "@arcgis/core/core/urlUtils";
import Extent from "@arcgis/core/geometry/Extent";
import FullscreenVM from "@arcgis/core/widgets/Fullscreen/FullscreenViewModel";
import Draw from "@arcgis/core/views/draw/Draw.js";
import Graphic from "@arcgis/core/Graphic";
import Field from "@arcgis/core/layers/support/Field.js";
import LabelClass from "@arcgis/core/layers/support/LabelClass.js";
import * as geometryEngine from "@arcgis/core/geometry/geometryEngine.js";
import * as query from "@arcgis/core/rest/query.js";
import Query from "@arcgis/core/rest/support/Query.js";

import egleImg from '../public/egle.png';

type ESRIProps = {

//
};

let draw: Draw;
let vdbLayer: Graphic;
let searchGraphic: Graphic;
let allGraphics: Graphic[] = [];
let view: MapView;

const vdbPointUrl: string = 'https://gis.lgia.gov.lv/arcgis/rest/services/VIETVARDI/vdb_publ_basic/MapServer/0'
const vdbLineUrl: string = 'https://gis.lgia.gov.lv/arcgis/rest/services/VIETVARDI/vdb_publ_basic/MapServer/1'
const vdbPolyUrl: string = 'https://gis.lgia.gov.lv/arcgis/rest/services/VIETVARDI/vdb_publ_basic/MapServer/2'




export function initializeMap(container: HTMLDivElement) {

  const spatialReference: SpatialReference = new SpatialReference({
    wkid:3059
  })

  const fullExtent = new Extent({
    xmin:79474.31123199109,
    ymin:14768.551371512585,
    xmax: 938048.374165567,
    ymax: 611173.5463562229,
    spatialReference:{
      wkid:3059
    }
  })

  const constraints: any = {
    rotationEnabled: false,
    snapToZoom: true,
    geometry:fullExtent,
    lods: [
      {
        level: 0,
        levelValue: '0',
        resolution: 529.1677250021168,
        scale: 2000000,
      },
      {
        level: 1,
        levelValue: '1',
        resolution: 264.5838625010584,
        scale: 1000000,
      },
      {
        level: 2,
        levelValue: '2',
        resolution: 132.2919312505292,
        scale: 500000,
      },
      {
        level: 3,
        levelValue: '3',
        resolution: 66.1459656252646,
        scale: 250000,
      },
      {
        level: 4,
        levelValue: '4',
        resolution: 26.458386250105836,
        scale: 100000,
      },
      {
        level: 5,
        levelValue: '5',
        resolution: 13.229193125052918,
        scale: 50000,
      },
      {
        level: 6,
        levelValue: '6',
        resolution: 6.614596562526459,
        scale: 25000,
      },
      {
        level: 7,
        levelValue: '7',
        resolution: 2.6458386250105836,
        scale: 10000,
      },
      {
        level: 8,
        levelValue: '8',
        resolution: 1.3229193125052918,
        scale: 5000,
      },
      {
        level: 9,
        levelValue: '9',
        resolution: 0.5291677250021167,
        scale: 2000,
      },
      {
        level: 10,
        levelValue: '10',
        resolution: 0.26458386250105836,
        scale: 1000,
      },
      {
        level: 11,
        levelValue: '11',
        scale: 500,
      },
      {
        level: 12,
        levelValue: '12',
        scale: 200,
      },
    ],
  }

  const center: any = {
    x: 506887.35,
    y: 311986.74,
    spatialReference: {
      wkid: 3059,
    },
  }

  //BASELAYERS
  const baseLayers: Collection<Basemap> = new Collection([
    new Basemap({
      id:'fonaMelnbalta',
      spatialReference,
      baseLayers: [
        new TileLayer({
          url: "https://gis.lgia.gov.lv/arcgis/rest/services/KP_BASEMAPS/MelnbaltaFonaKarte/MapServer",
          spatialReference,
        }),
      ],
    }),
    new Basemap({
      id:'fonaKombineta',
      spatialReference,
      baseLayers: [
        new TileLayer({
          url: 'https://gis.lgia.gov.lv/arcgis/rest/services/KP_BASEMAPS/KrasainaFonaKarte/MapServer',
          spatialReference,
        }),
      ],
    }),
    new Basemap({
      id:'parskataKarte',
      spatialReference,
      baseLayers: [
        new TileLayer({
          url: 'https://gis.lgia.gov.lv/arcgis/rest/services/KP_BASEMAPS/TopoParskata/MapServer',
          spatialReference,
        }),
      ],
    }),
    new Basemap({
      id: 'ortofoto8',
      spatialReference,
      baseLayers:[
        new TileLayer({
          url: 'https://gis.lgia.gov.lv/arcgis/rest/services/KP_BASEMAPS/Ortofoto_kopa_aktual_RGB/MapServer',
          spatialReference,
        })
      ]
    }),
    new Basemap({
      id: 'ortofoto8CIR',
      spatialReference,
      baseLayers:[
        new TileLayer({
          url: 'https://gis.lgia.gov.lv/arcgis/rest/services/KP_BASEMAPS/Ortofoto_kopa_aktual_CIR/MapServer',
          spatialReference,
        })
      ]
    }),
    new Basemap({
      id: 'topo50k',
      spatialReference,
      baseLayers:[
        new TileLayer({
          url: 'https://gis.lgia.gov.lv/arcgis/rest/services/KP_BASEMAPS/Topo50_v3/MapServer',
          spatialReference,
        })
      ]
    }),
    new Basemap({
      id: 'topo10k',
      spatialReference,
      baseLayers:[
        new TileLayer({
          url: 'https://gis.lgia.gov.lv/arcgis/rest/services/KP_BASEMAPS/Topo10_kopa_aktual/MapServer',
          spatialReference,
        })
      ]
    }),
  ])

  const initialBasemap = localStorage.getItem('basemap') ? localStorage.getItem('basemap') : 'fonaMelnbalta';

  //COMPONENTS
  const map = new Map({
    basemap: baseLayers.find(function (basemap) {
      return basemap.id === initialBasemap;
    }),
    basemapCollection: baseLayers,
  });

  view = new MapView({
    map,
    container,
    center,
    spatialReference,
    spatialReferenceLocked:true,
    constraints,
    zoom:4
  });

  //UI
  view.ui.remove("zoom");
  view.ui.add([
    {
      component: "panel",
      position: "top-left",
    },
    {
      component: "mapFullscreenButton",
      position: "bottom-left",
    }
  ]);

  //WIDGETS
  draw = new Draw({
    view
  })
  return {view, draw};
}

export function createPolygon(verticles, view, action){
  view.graphics.removeAll();
  let symbol, geometry;

  if(verticles.length>2){
    symbol = {
      type: 'simple-fill',
      color: 'white',
      style: 'none',
      outline:{
        color: 'green',
        style:action === 'create' ? 'short-dash' : 'solid',
        width:2.5
      }
    }

    geometry = {
      type:'polygon',
      rings: verticles,
      spatialReference: view.spatialReference
    }
  } else {
    symbol = {
      type:'simple-line',
      color: 'green',
      width:2.5,
      style:'short-dash'
    }
  
    geometry = {
      type:'polyline',
      paths: verticles,
      spatialReference: view.spatialReference
    }
  }

  searchGraphic = new Graphic({
    geometry,
    symbol 
  })

  if(action !== 'complete'){
    view.graphics.add(searchGraphic);
  }

  return searchGraphic;
}

export function initializeFullscreen(view: MapView) {
  const fullscreen = new FullscreenVM({
    view
  })

  return fullscreen;
}

export function drawSearchPerimeter(view: MapView){
  if(draw){
    return draw.create('polygon', {mode:'click'});
  }
}

export function cancelDrawSearchPerimeter(){
  searchGraphic = undefined;

  if(draw){
    draw.reset();
  }
}

export function clearSearchPerimeter(view: MapView){
  if(searchGraphic){
    view.graphics.removeAll();
  }
  
  cancelDrawSearchPerimeter();
  return true;
}

export async function filterLayer(objectIds: number[]){
  	const queryIds = objectIds.filter(id => {
      const isIdAlreadyQueried = allGraphics.find(graphic => graphic.attributes.OBJEKTAID === id);
      if(!isIdAlreadyQueried){
        return id;
      }
    })

    if(queryIds.length > 0){
    const queryObject = new Query({
      where: `OBJEKTAID IN (${queryIds.join()})`,
      returnGeometry: true,
      outFields: ['OBJECTID','OBJEKTAID', 'PAMATNOSAUKUMS', 'VEIDS1'],
      outSpatialReference: { wkid: 3059 }
    }), graphics: any[] = [];

/*     const pointQuery = vdbLayer.sublayers.items[0].queryFeatures(query);
    const lineQuery = vdbLayer.sublayers.items[1].queryFeatures(query);
    const polygonQuery = vdbLayer.sublayers.items[2].queryFeatures(query); */
    const pointQuery = query.executeQueryJSON(vdbPointUrl, queryObject);
    const lineQuery = query.executeQueryJSON(vdbLineUrl, queryObject);
    const polyQuery = query.executeQueryJSON(vdbPolyUrl, queryObject);

    await Promise.all([pointQuery, lineQuery, polyQuery].map(p => p.catch(customError => customError
     ))).then(results => {
      
      if(results){
        for(let index = 0; index < results.length; index++){
          if(results[index].features){
            results[index].features.forEach(feature => {
              graphics.push(feature)
            })
          }

        }
      } 
    }).catch(customError => console.error(customError))
  

    graphics.forEach(graphic => {

      if(!allGraphics.find(item => graphic.attributes.OBJECTID === item.attributes.OBJECTID)){

        allGraphics.push(graphic)
      }
    })
  }

    return objectIds;
}

export function filterGraphicCollection (allObjectIds: number[]){
  const newGraphicCollection: any[] = [];
  allGraphics.forEach(graphic => {
    if(allObjectIds.find(id => graphic.attributes.OBJEKTAID === id)){
      newGraphicCollection.push(graphic)
    }
  })

  allGraphics = newGraphicCollection;
}

export function createLayer(objectIds: number[], view, currentExtent){
  view.graphics.removeAll();
  console.log(objectIds)
  if(currentExtent){
    view.graphics.add(currentExtent);
  }
  const vdbPointLayer = view.map.findLayerById('vdbPointFeatureLayer');
  const vdbLineLayer = view.map.findLayerById('vdbLineFeatureLayer');
  const vdbPolygonLayer = view.map.findLayerById('vdbPolygonFeatureLayer');

  if(vdbPointLayer){
    vdbPointLayer.destroy();
  };
  
  if(vdbLineLayer){
    vdbLineLayer.destroy();
  }

  if(vdbPolygonLayer){
    vdbPolygonLayer.destroy();
  }

  const graphicsPoint = new Collection([]);
  const graphicsLine = new Collection([]);
  const graphicsPolygon = new Collection([]);
  const visibleIds = [];

  objectIds.forEach(id => {
    const graphics = allGraphics.filter(graphic => Number(id) === graphic.attributes.OBJEKTAID)
    let isWithinBounds = true;
    graphics.forEach(graphic => {
      if(currentExtent){
        if(!geometryEngine.contains(currentExtent.geometry, graphic.geometry)){
          isWithinBounds = false;
          return
        }
      }

      if(graphic){
        switch(graphic.geometry.type){
          case 'point': graphicsPoint.push(graphic); break;
          case 'polyline': graphicsLine.push(graphic); break;
          case 'polygon': graphicsPolygon.push(graphic); break;
          default: break;
        }
      }
    })

    if(isWithinBounds){
      visibleIds.push(id)
    }
  })

  const treeSymbol = {
    type: 'picture-marker',
    url: egleImg.src,
    width: '12px',
    height:'12px'
  }

  const rendererPoint = {
    type:'unique-value',
    field: 'VEIDS1',
    defaultSymbol: treeSymbol
  }

  const rendererLine ={
    type:'unique-value',
    field: 'VEIDS1',
    defaultSymbol: {
      type: 'simple-line',
      color: [0, 118, 44, 0.4],
      width: "12px",
      join:'round',
      cap:'butt'
    }
  }

  const rendererPolygon ={
    type:'unique-value',
    field: 'VEIDS1',
    defaultSymbol: {
      type: 'simple-fill',
      color: [0, 118, 44, 0.2 ],
      style: "solid",
      outline: {
        color: [0, 118, 44, 0.5 ],
        width: 4,
        join:'round',
        cap:'butt'
      }
    }
  }

  view.map.add(new FeatureLayer({
    id:'vdbPointFeatureLayer',
    layerId: 0,
    source: graphicsPoint,
    editingEnabled: false,
    geometryType: 'point',
    legendEnabled: false,
    objectIdField: 'OBJECTID',
    popupEnabled: false,
    spatialReference:{
      wkid: 3059,
    },
    renderer: rendererPoint,
    visible: true,
    labelingInfo: [
      new LabelClass({
        labelExpressionInfo: {expression: '$feature.PAMATNOSAUKUMS'},
        symbol: {
          type: 'text',
          font:{
            family:'Arial',
            size:10,
            weight: 'bold',
          },
          color: [0, 118, 44, 1],
          haloSize: 2,
          haloColor: [235, 235, 225, 1],
          xoffset: '-10px',
          yoffset:'-7px'
        },
        labelPlacement: 'above-right'
      }),
    ],
    fields:[
      new Field({
        name: 'OBJECTID',
        alias:'OBJECTID',
        type:'oid',
        nullable: false
      }),
      new Field({
        name: 'OBJEKTAID',
        alias: 'OBJEKTAID',
        type:'integer',
        nullable: false
      }),
      new Field({
        name: 'PAMATNOSAUKUMS',
        alias: 'PAMATNOSAUKUMS',
        type:'string',
        nullable: false
      }),
      new Field({
        name: 'VEIDS1',
        alias: 'VEIDS1',
        type:'string',
        nullable: false
      }),
    ],
    outFields:['OBJEKTAID']
  }))

  view.map.add(new FeatureLayer({
    id:'vdbLineFeatureLayer',
    layerId: 0,
    source: graphicsLine,
    editingEnabled: false,
    geometryType: 'polyline',
    legendEnabled: false,
    objectIdField: 'OBJECTID',
    popupEnabled: false,
    spatialReference:{
      wkid: 3059,
    },
    renderer: rendererLine,
    visible: true,
    labelingInfo: [
      new LabelClass({
        labelExpressionInfo: {expression: '$feature.PAMATNOSAUKUMS'},
        symbol: {
          type: 'text',
          font:{
            family:'Arial',
            size:10,
            weight: 'bold',
          },
          color: [0, 118, 44, 1],
          haloSize: 2,
          haloColor: [235, 235, 225, 1],
          xoffset: '-10px',
          yoffset:'-7px'
        },
        labelPlacement: 'center-along'
      }),
    ],
    fields:[
      new Field({
        name: 'OBJECTID',
        alias:'OBJECTID',
        type:'oid',
        nullable: false
      }),
      new Field({
        name: 'OBJEKTAID',
        alias: 'OBJEKTAID',
        type:'integer',
        nullable: false
      }),
      new Field({
        name: 'PAMATNOSAUKUMS',
        alias: 'PAMATNOSAUKUMS',
        type:'string',
        nullable: false
      }),
      new Field({
        name: 'VEIDS1',
        alias: 'VEIDS1',
        type:'string',
        nullable: false
      }),
    ],
    outFields:['OBJEKTAID']
  }))

  view.map.add(new FeatureLayer({
    id:'vdbPolygonFeatureLayer',
    layerId: 0,
    source: graphicsPolygon,
    editingEnabled: false,
    geometryType: 'polygon',
    legendEnabled: false,
    objectIdField: 'OBJECTID',
    popupEnabled: false,
    spatialReference:{
      wkid: 3059,
    },
    renderer: rendererPolygon,
    visible: true,
    labelingInfo: [
      new LabelClass({
        labelExpressionInfo: {expression: '$feature.PAMATNOSAUKUMS'},
        symbol: {
          type: 'text',
          font:{
            family:'Arial',
            size:10,
            weight: 'bold',
          },
          color: [0, 118, 44, 1],
          haloSize: 2,
          haloColor: [235, 235, 225, 1],
          xoffset: '-10px',
          yoffset:'-7px'
        },
        labelPlacement: 'always-horizontal'
      }),
    ],
    fields:[
      new Field({
        name: 'OBJECTID',
        alias:'OBJECTID',
        type:'oid',
        nullable: false
      }),
      new Field({
        name: 'OBJEKTAID',
        alias: 'OBJEKTAID',
        type:'integer',
        nullable: false
      }),
      new Field({
        name: 'PAMATNOSAUKUMS',
        alias: 'PAMATNOSAUKUMS',
        type:'string',
        nullable: false
      }),
      new Field({
        name: 'VEIDS1',
        alias: 'VEIDS1',
        type:'string',
        nullable: false
      }),
    ],
    outFields:['OBJEKTAID']
  }))

  console.log(currentExtent + " current extent")

  if(currentExtent){
    view.goTo(currentExtent.geometry)
  } else if([...graphicsPoint, ...graphicsLine, ...graphicsPolygon].length <= 0){
    return visibleIds
  }else {
    view.goTo([...graphicsPoint, ...graphicsLine, ...graphicsPolygon])
  }
  

  return visibleIds;
}