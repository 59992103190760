'use client';
import React, { useState } from "react";


function AlertWindow({ text }: any) {
    return <>
        <div className='alert-container'>

            <div className='alert-text'>
                {text}
            </div>

        </div>
    </>
}

export default AlertWindow;

