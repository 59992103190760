// @ts-nocheck
'use client';
//import '../sass/main.scss';
import React from 'react';
import { HiOutlineSearch } from "react-icons/hi";

import { useGlobalContext } from '../app/Context/store';
import { GlobalContextProvider } from '../app/Context/store';

import '../styles/style.css';

type HeaderProps = {
    onMenu?: React.ChangeEventHandler<HTMLInputElement>;
    isMenuOpen: boolean;
    handleSearchVisibility: boolean;
}

export default function Header() {


    const { handleMenu, isMenuOpen, handleSearchVisibility } = useGlobalContext();

    return (
        <header className='head header'>


            <div className='header-menu'>
                <input
                    type='checkbox'
                    className='header-menu__checkbox'
                    id='menu-toggle'
                    checked={isMenuOpen}
                    onChange={handleMenu}
                />
                <label htmlFor="menu-toggle" className='header-menu__button'>
                    <span className='header-menu__icon'>&nbsp;</span>
                </label>


            </div>



            <button className='header__search__button' onClick={handleSearchVisibility}><HiOutlineSearch /></button>



            <h1 className="header__title heading-primary">
                <span className='heading-primary--main'>Vietvārdu datubāze</span>
                <span className='heading-primary--sub'>Publiskā versija</span>
            </h1>

        </header >
    );
}