// @ts-nocheck
'use client';
import React, { useEffect, useState } from 'react';
import * as reactiveUtils from "@arcgis/core/core/reactiveUtils.js";

//CATEGORY IMAGES
import topoCategoryImg from '@Image/grupa-topo.png';
import ortoCategoryImg from '@Image/grupa-orto.png';
import fonaCategoryImg from '@Image/grupa-fona.png';

//LAYER IMAGES
import orto7Img from '@Image/orto7.png';
import orto7CirImg from '@Image/orto7cir.png';
import orto8Img from '@Image/orto8.png';
import orto8CirImg from '@Image/orto8cir.png';
import combinedImg from '@Image/kombineta.png';
import greyImg from '@Image/melnbalta.png';
import topo10Img from '@Image/topo10.png';
import topo25Img from '@Image/topo25.png';


import { LuX } from 'react-icons/lu'

import CopyrightWindow from './_copyright-window';


const copyrightText: string = "©Latvijas Ģeotelpiskās informācijas aģentūra, ©OpenStreetMap, ©Valsts Zemes dienests"
const copyrightTextLGIA: string = "©Latvijas Ģeotelpiskās informācijas aģentūra"
const copyrightTextOrto: string = "©Latvijas Ģeotelpiskās informācijas aģentūra, ©Contains modified Copernicus Sentinel data 2023"


/* kombinētajai un melnbalatajaii paliek*/


let basemapHandle;



function Layer({ name = '', id = '', backgroundImage = '', active }) {
    return (
        <div className='layerlist__layers--layer'>
            <input type="radio" name="baselayers" id={id} checked={active === id} readOnly />
            <label htmlFor={id}>

                <button className='layerlist__layers--icon' style={{ backgroundImage: `url(${backgroundImage})` }} />
                <div className='layerlist__layers--title'>{name}</div>
            </label>
        </div>
    );
}
function Category({ id = '', name = '', backgroundImage = '', activeCategory = '' }) {
    return (
        <div className='layerlist__categories--category'>
            <input type="radio" name="category" id={id} checked={activeCategory === id} readOnly />
            <label htmlFor={id}>
                <button className='layerlist__categories--icon' style={{ backgroundImage: `url(${backgroundImage})` }} />

                <p className='layerlist__categories--title'>{name}</p>
            </label>
        </div>
    )
}

export default function LayerList({ view, onMenu, layerlistState, setLayerlistState }) {
    const [category, setCategory] = useState(localStorage.getItem("category") || "base");
    const [basemap, setBasemap] = useState(localStorage.getItem("basemap") || '');


    /// ŠIM JĀSTRĀDĀ
    useEffect(() => {
        if (view) {
            setBasemap(view.map.basemap.id)
        }

        basemapHandle = reactiveUtils.watch(() => view.map.basemap, () => {
            setBasemap(view.map.basemap.id)
        })

        return () => {
            if (basemapHandle) {
                basemapHandle.remove();
            }
        }
    }, [])

    const handleCategoryChange = ({ target }) => {
        const newCategory = target.id;
        setCategory(target.id);
        if (localStorage.getItem('cookies') === 'accept') {
            localStorage.setItem("category", newCategory);
        }
    }

    const handleCloseLayerList = (layerlistState) => {
        setLayerlistState(false);
    }

    const handleBasemapChange = ({ target }) => {
        const newBasemap = view.map.basemapCollection.find((layer) => layer.id === target.id);
        view.map.basemap = newBasemap;

        if (localStorage.getItem('cookies') === 'accept') {
            localStorage.setItem("basemap", newBasemap.id);
        }
    }

    return (
        <div id='layerlist' className='layerlist'>
            <div className='layerlist__header'>
                {/* <Info /> */}
                <span><h2 className='heading-secondary'>Kartes slāņi</h2></span>
                <div className='layerlist__header__close-layerlist' onClick={handleCloseLayerList}><LuX /></div>

            </div>
            <div className='layerlist__categories' onChange={handleCategoryChange}>
                <Category name='Fona kartes' id='base' backgroundImage={fonaCategoryImg.src} activeCategory={category} />
                <Category name='Topogrāfiskās kartes' id='topo' backgroundImage={topoCategoryImg.src} activeCategory={category} />
                <Category name='Ortofotokartes' id='orto' backgroundImage={ortoCategoryImg.src} activeCategory={category} />
            </div>
            <div className='layerlist__layers' onChange={handleBasemapChange}>
                <div className={`layerlist__layers--card ${category}`}>
                    <div className='layerlist__layers--item'>
                        <div className='layerlist__layers--copyright'>
                            <CopyrightWindow text={copyrightText} src="/info.svg" alt="info icon" width={20} height={20} />

                        </div>
                        <Layer name='Kombinētā fona karte' id='fonaKombineta' backgroundImage={combinedImg.src} active={basemap} />
                    </div>

                    <div className='layerlist__layers--item'>
                        <div className='layerlist__layers--copyright'>
                            <CopyrightWindow text={copyrightText} src="/info.svg" alt="info icon" width={20} height={20} />
                        </div>
                        <Layer name='Melnbaltā fona karte' id='fonaMelnbalta' backgroundImage={greyImg.src} active={basemap} />
                    </div>

                    <div className='layerlist__layers--item'>
                        <div className='layerlist__layers--copyright'>
                            <CopyrightWindow text={copyrightTextLGIA} src="/info.svg" alt="info icon" width={20} height={20} />
                        </div>
                        <Layer name='Pārskata un topogrāfiskās kartes' id='parskataKarte' backgroundImage={topoCategoryImg.src} active={basemap} />
                    </div>
                </div>
                <div className={`layerlist__layers--card ${category}`}>
                    <div className='layerlist__layers--item'>
                        <div className='layerlist__layers--copyright'>
                            <CopyrightWindow text={copyrightTextLGIA} src="/info.svg" alt="info icon" width={20} height={20} />
                        </div>
                        <Layer name='Topogrāfiskā karte 1:50 000' id='topo50k' backgroundImage={topo25Img.src} active={basemap} />
                    </div>
                    <div className='layerlist__layers--item'>
                        <div className='layerlist__layers--copyright'>
                            <CopyrightWindow text={copyrightTextLGIA} src="/info.svg" alt="info icon" width={20} height={20} />
                        </div>
                        <Layer name='Topogrāfiskā karte 1:10 000' id='topo10k' backgroundImage={topo10Img.src} active={basemap} />
                    </div>
                </div>
                <div className={`layerlist__layers--card ${category}`}>
                    <div className='layerlist__layers--item'>
                        <div className='layerlist__layers--copyright'>
                            <CopyrightWindow text={copyrightTextOrto} src="/info.svg" alt="info icon" width={20} height={20} />
                        </div>
                        <Layer name='Ortofoto jaunāko datu pārklājums (RGB)' id='ortofoto8' backgroundImage={orto8Img.src} active={basemap} />
                    </div>
                    <div className='layerlist__layers--item'>
                        <div className='layerlist__layers--copyright'>
                            <CopyrightWindow text={copyrightTextOrto} src="/info.svg" alt="info icon" width={20} height={20} />
                        </div>
                        <Layer name='Ortofoto jaunāko datu pārklājums (CIR)' id='ortofoto8CIR' backgroundImage={orto8CirImg.src} active={basemap} />
                    </div>
                </div>
            </div>
        </div>

    )
}

