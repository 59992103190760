import React from 'react';

type MapButtonProps = {
    icon?: string;
    id?: string;
    className?: string;
    onButtonClick?: () => void;
    toggle?: boolean;
    active?: boolean;
    title?: string;
};

export default function MapButton({ icon = '\u00A0', id = '', className = '', onButtonClick, toggle = false, active = false, title = '' }: MapButtonProps) {
    if (toggle) {
        return (
            <div className={`map-button map-button__toggle ${className}`} title={title} id={id} onClick={onButtonClick}>
                <input type='checkbox' className='map-button__checkbox' id={id + 'Checkbox'} checked={active} readOnly />
                <label htmlFor={id + 'Checkbox'} className='map-button__button' >
                    <span className="map-button__icon">
                        {icon}
                    </span>
                </label>
            </div>
        )
    }

    return (
        <button className={`map-button ${className}`} title={title} onClick={onButtonClick}>
            <span className="map-button__icon">
                {icon}
            </span>
        </button>
    )
}