'use client';
import React, { useState } from "react";
import Image from 'next/image';

interface CopyrightWindowProps {
    text: string;
    icon: any;
    src: string;
    width: number;
    height: number;
    alt: string
}


export default function CopyrightWindow({ text, icon, src, alt, width, height }: CopyrightWindowProps) {
    return <>

        <div className='copyright-container'>
            <div className="copyright-icon">
                <Image
                    src={src}
                    alt={alt}
                    width={width}
                    height={height}
                />
                {icon}
            </div>
            <div className='copyright-text'>
                {text}
            </div>

        </div>
    </>
}



