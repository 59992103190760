// @ts-nocheck
'use client';

import React, { useState, useEffect, useRef } from 'react';

//ARCGIS
import * as reactiveUtils from "@arcgis/core/core/reactiveUtils.js";

import { initializeMap, initializeFullscreen, drawSearchPerimeter, clearSearchPerimeter, filterLayer, createPolygon, filterGraphicCollection, createLayer, cancelDrawSearchPerimeter } from '@Esri/map';
import MapButton from '@Components/_map-button';
import LayerList from '../components/_layerList';

import { useGlobalContext } from '../app/Context/store';

import { LuPlus, LuMinus, LuExpand, LuShrink, LuLayers, LuScaling, LuSquareSlash } from 'react-icons/lu';

let fullscreen, fullscreenHandle, mouseMoveHandle, mouseClickHandle, allExtentGraphics = [];

export default function Map() {
    const { data, handleFilterByMap, activeTab, setActiveTab, tabCollection, onFilteredTabCollection, onFetchAPI, onObjectViewCreate, onObjectFetchAPI, cookieOption } = useGlobalContext()

    const container = useRef() as any;
    const [view, setView] = useState<View | null>(null);
    const [fullscreenState, setFullscreenState] = useState<Boolean>(false);
    const [layerlistState, setLayerlistState] = useState<Boolean>(false);
    const [extentState, setExtent] = useState<Boolean>(false);

    useEffect(() => {
        async function initialize() {
            const { view } = await initializeMap(container.current);

            setView(view);

            handleMouseMove(view);
            handleMouseClick(view);

            fullscreen = await initializeFullscreen(view);

            fullscreenHandle = reactiveUtils.watch(
                () => fullscreen.state,
                () => {
                    if (fullscreen.state === 'ready') {
                        setFullscreenState(false);
                    } else {
                        setFullscreenState(true);
                    }
                }
            )
        }

        initialize();

        return () => {
            if (fullscreenHandle) {
                fullscreenHandle.remove();
            }

            if (mouseMoveHandle) {
                mouseMoveHandle.remove();
            }

            if (mouseClickHandle) {
                mouseClickHandle.remove();
            }
        }
    }, [container]);

    useEffect(() => {
        const currentTab = tabCollection.find(tab => activeTab === tab.tabId);

        if (currentTab && view) {
            handleFilterByIds(currentTab.items, currentTab.tabId);
        }

        //cancelDrawSearchPerimeter();
    }, [activeTab, tabCollection])

    /*     useEffect(() => {
            console.log('tabCollection state')
            console.log(tabCollection) */
    //const allObjectIds = [];
    /* tabCollection.forEach(tab => {
        allObjectIds.push(...tab.items)
    }) */
    //filterGraphicCollection(allObjectIds)
    /*     }, [tabCollection]) */

    useEffect(() => {
        const currentTab = tabCollection.find(tab => activeTab === tab.tabId);
        const currentExtent = allExtentGraphics.findIndex(graphic => graphic.tabId === activeTab)

        if (currentTab) {
            if (currentTab.items.length > 1 && currentExtent === -1 && extentState === true) {
                handleDraw(drawSearchPerimeter(view));
            } else if (extentState === false && currentExtent === -1) {
                clearSearchPerimeter(view);
            } else if (currentExtent > -1) {
                handleFilterByIds(currentTab.items, currentTab.tabId);
            }
        }
    }, [extentState]);

    const redrawGraphics = (itemArr, tabId) => {
        const currentExtent = allExtentGraphics.find(graphic => graphic.tabId === tabId);
        let extentGraphic;
        if (currentExtent && currentExtent.visible) {
            extentGraphic = currentExtent.graphic;
            setExtent(currentExtent.visible);
        } else {
            setExtent(false);
        }

        if (view) {
            const filteredIds = createLayer(itemArr, view, extentGraphic)

            return {
                id: tabId,
                items: filteredIds
            }
        }
    }

    const handleZoomIn = () => {
        view.zoom++;
    };

    const handleZoomOut = () => {
        view.zoom--;
    };

    const handleFullSCreen = () => {
        fullscreen.toggle();
    }

    const handleDraw = (drawAction) => {
        drawAction.on('vertex-add', function (e) {
            createPolygon(e.vertices, view, 'create')
        })

        drawAction.on('cursor-update', function (e) {
            createPolygon(e.vertices, view, 'create')
        })

        drawAction.on('draw-complete', function (e) {
            allExtentGraphics.push({
                tabId: activeTab,
                graphic: createPolygon(e.vertices, view, 'complete'),
                visible: true
            });
            redrawGraphics([]);
        })

        drawAction.on('vertex-remove', function (e) {
            createPolygon(e.vertices, view, 'create')
        })
    }

    const handleMenu = () => {
        const newState = layerlistState ? false : true;
        setLayerlistState(newState)
    }

    const handleSpliceSearchExtent = () => {
        const currentExtent = allExtentGraphics.findIndex(graphic => graphic.tabId === activeTab)
        if (currentExtent > -1) {
            allExtentGraphics.splice(currentExtent, 1);
        }
        view.graphics.removeAll();
    }

    const handleSearchExtent = () => {
        const currentExtent = allExtentGraphics.find(graphic => graphic.tabId === activeTab);
        const currentTab = tabCollection.find(tab => activeTab === tab.tabId);
        if (currentExtent) {
            currentExtent.visible = !currentExtent.visible;
            setExtent(currentExtent.visible)
        } else if (currentTab && currentTab.items.length) {
            setExtent(!extentState)
        }
    }

    const handleClearSearchExtent = () => {
        const currentTab = tabCollection.find(tab => activeTab === tab.tabId);

        handleSpliceSearchExtent();
        handleFilterByIds(currentTab.items, currentTab.tabId);
        setExtent(false);
    }

    const handleFilterByIds = (items, tabId) => {
        if (view) {
            (async () => {
                const newIds = await filterLayer(items)
                const filteredIds = redrawGraphics(newIds, tabId);
                //atgriež derīgus filtrētus ID, kas nāk no rāmja. Te būs problēmas.
                onFilteredTabCollection(filteredIds)
            })();
        }
    }

    const handleMouseMove = (view) => {
        if (view && container.current) {
            const mapStyle = container.current.style;
            mapStyle.cursor = 'auto';
            mouseMoveHandle = view.on('pointer-move', (event) => {

                if (event.pointerType === 'mouse') {
                    view.hitTest(event, { exclude: view.graphics }).then((response) => {
                        if (response.results.length && mapStyle.cursor === 'auto') {
                            mapStyle.cursor = 'pointer';
                        } else if (!response.results.length && mapStyle.cursor === 'pointer') {
                            mapStyle.cursor = 'auto';
                        }
                    })
                }
            })
        }
    }

    const handleMouseClick = (view) => {
        mouseClickHandle = view.on('click', (event) => {
            view.hitTest(event, { exclude: view.graphics }).then((response) => {
                console.log(response)
                if (response.results.length) {
                    onObjectViewCreate(
                        response.results[0].graphic.attributes.OBJEKTAID,
                        response.results[0].graphic.attributes.PAMATNOSAUKUMS,
                        response.results[0].graphic.attributes.VEIDS1
                    )
                }
            })
        })
    }

    return (
        <div ref={container} className='content map'>
            <div className="panel" id='panel'>
                <div>
                    <MapButton onButtonClick={handleZoomIn} title='Pietuvināt' icon={<LuPlus />} />
                    <MapButton onButtonClick={handleZoomOut} title='Attālināt' icon={<LuMinus />} />
                </div>
                <MapButton onButtonClick={handleMenu} title='Karšu slāņi' id='mapMenuButton' toggle={true} icon={<LuLayers />} active={layerlistState} />
                <MapButton onButtonClick={handleSearchExtent} title='Meklēšanas perimetrs' id='mapExtentButton' icon={<LuScaling />} toggle={true} active={extentState} />
                <MapButton onButtonClick={handleClearSearchExtent} title='Dzēst meklēšanas perimentru' id='mapClearExtentButton' icon={<LuSquareSlash />} />
            </div>

            <MapButton
                className="map-button__fullscreen"
                id="mapFullscreenButton"
                onButtonClick={handleFullSCreen}
                icon={fullscreenState ? <LuShrink /> : <LuExpand />}
                title='Pilnekrāna režīms'
                toggle={true}
                active={fullscreenState}
            />

            {layerlistState && <LayerList view={view} onMenu={handleMenu} layerlistState={layerlistState} setLayerlistState={setLayerlistState} />}
        </div>
    )
}
