// @ts-nocheck
'use client';
import React from 'react';
import { useState } from 'react';
//import AdmIzm from './explanations/AdmIzm.jsx';
//import AdmPieder from './explanations/AdmPieder.jsx';
//import Ietilp from './explanations/Ietilp.tsx';
import { useGlobalContext } from '../Context/store';

import { useRouter } from 'next/navigation';

type MenuProps = {
    isOpen: boolean;
    onOpenExplanation: Function;
    tabComponents: any;
    onMenu: Function;
}

export default function Menu() {

    const { isOpen, onOpenExplanation, tabComponents, onMenu } = useGlobalContext();

    const explainItems = [
        'Vietvārds jeb nosaukums',
        'Vietvārdu veidi',
        'Objektu veidi',
        'Vietvārdu avoti',
        'Skaitliskais raksturojums',
        'Objektu stāvoklis',
        'Koordinātas',
        'Administratīvā piederība',
        /*         'Vietvārdu izruna', */
        'Vietvārdu transliterācija',
        'Ietilpstošie (ietekošie) objekti',
        'LR normatīvie akti',
        'Ieteikumi vietvārdu jomā',
        'Ārvalstu resursi vietvārdu jomā'



    ];
    const [selectedExplanation, setSelectedExplanation] = useState(null);
    const htmlContents = {
        //AdmIzm,
        //AdmPieder,
        //'Ietilpstošie(ietekošie) objekti': Ietilp
        // ... (other HTML files)
    };

    const router = useRouter();

    const handleItemClick = (item: any) => {

        setSelectedExplanation(htmlContents[item]);
        if (item in tabComponents) {
            onOpenExplanation(item);
            router.push(`/search?explain=${item}`, undefined, { shallow: true });
        }
        onMenu();
        router.push(`/search?explain=${item}`, undefined, { shallow: true });
    };

    return (

        <div className={`menu ${isOpen ? 'menu-active' : ''}`}>
            <h1>Paskaidrojumi</h1>
            <ul className="list-items">
                {explainItems.map((item, index) => (
                    <li key={index} onClick={() => handleItemClick(item)}>
                        {item}
                    </li>
                ))}
            </ul>
            <h1>Saites</h1>
            <ul className="list-items">
                <li onClick={() => handleItemClick('LR normatīvie akti')}>LR normatīvie akti</li>
                <li onClick={() => handleItemClick('Ieteikumi vietvārdu jomā')}>Ieteikumi vietvārdu jomā</li>
                <li onClick={() => handleItemClick('Ārvalstu resursi vietvārdu jomā')}>Ārvalstu resursi vietvārdu jomā</li>

                <li><a href="http://unstats.un.org/unsd/geoinfo/UNGEGN/publications.html" target="_blank" rel="nofollow">Mācību un metodiskie materiāli</a></li>
                <li><a href="https://www.lgia.gov.lv/lv/vietvardu-datubaze" target="_blank" rel="nofollow">Par Vietvārdu datubāzi</a></li>
                <li><a href="https://www.lgia.gov.lv/lv/darbibas-jomas/toponimikas-laboratorija" target="_blank" rel="nofollow">Par LĢIA Toponīmikas laboratoriju</a></li>
                <li><a href="https://www.lgia.gov.lv/lv/darbibas-jomas" target="_blank" rel="nofollow">Vēl par vietvārdiem</a></li>
                <li><a href="https://www.lgia.gov.lv/lv/oficialie-vietvardi-dabas-objektiem" target="_blank" rel="nofollow">Oficiālie vietvārdi dabas objektiem</a></li>
            </ul>
            <p className="menu-footer"><br /> <br />Rakstiet mums: <a href="mailto:vdbinfo@lgia.gov.lv">vdbinfo@lgia.gov.lv</a></p>
        </div>
    );
}