'use client';
import React, { useState } from "react";


function TooltipWindow({ text, icon }: any) {
    return <>
        <div className='tooltip-container'>
            <div className="tooltip-icon">
                {icon}
            </div>
            <div className='tooltip-text'>
                {text}
            </div>

        </div>
    </>
}

export default TooltipWindow;

