import React from 'react';

type TextInputProps = {
    label?: string;
    onInputChange?: React.ChangeEventHandler<HTMLInputElement>;

    value?: string;
    id?: string;
    type?: string;
    disabled?: boolean;
}

export default function TextInput({ label = '', id = '', onInputChange, value, disabled = false }: TextInputProps) {

    return (
        <div className='search__input-container'>
            <label className='search__input-label' htmlFor={id}>{label}</label>
            <input className='search__input-text' type='text' id={id} value={value} onChange={onInputChange} disabled={disabled} />
        </div>
    );
}