//@ts-nocheck
'use client';

import React, { useState, useEffect } from 'react';
import CheckboxInput from './_checkboxInput';
import TextInput from './_textInput';
import DropdownInput from './_dropdownInput';

import { LuHelpCircle, LuLoader2, LuEraser, LuCheck } from 'react-icons/lu';


import PulseLoader from "react-spinners/PulseLoader";
import TooltipWindow from './_tooltip-window';
import AlertWindow from './_alert-window';

import { useGlobalContext } from '../app/Context/store';

type CheckboxState = {
    [key: string]: boolean;
};
type MunicipalityOption = {
    value: string;
    label: string;
}


type SearchWindowProps = {
    onSearchButtonClick: () => void;
    onClearSearch: () => void;
    onCheckboxChange: (name: string, isChecked: boolean) => void; //bad practice, avoid any
    onObjectIDSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onObjectNameSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onObjectTypeSearch: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    onObjectCountrySearch: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    onObjectMunicipalitySearch: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    //onFetchButtonClick: (event: MouseEventHandler<HTMLButtonElement>);
    uniqueTypes: { value: any; label: any; }[];
    setCountryID: React.Dispatch<React.SetStateAction<number>>;
    checkboxes: CheckboxState;
    checkboxOptions: any;
    countryData: string[];
    municipalityData: string[];
    municipalityOptions: MunicipalityOption[];
    isLoading: boolean;
    onFetchButtonClick: any;
    searchValues: any;
    showAlert: boolean;
    setShowAlert: React.Dispatch<React.SetStateAction<boolean>>;
    showAlertFor3Seconds: () => void;
    searchVisibility: boolean;
    idFieldActive: boolean;


};

const tooltipContent = `Meklējamā nosaukuma daļai jābūt vismaz 3 rakstzīmes garai.
Meklēšanu pēc nosaukuma daļas var precizēt, izmantojot % un _ zīmes.

% zīme norāda uz neviena, viena vai daudzu burtu ievietošanu tās vietā.
Nosaukuma daļas lodziņā ierakstot trīs % zīmes (%%%), tiks atlasīti visi vietvārdi, kuri atbildīs pārējiem meklēšanas tabulā norādītajiem nosacījumiem, t.i. tas ļaus meklēt vietvārdus, kuriem nav zināma pat trīs burtus gara daļa (piemēram, atlasīt oficiālos vietvārdus).
Nosaukuma daļas beigās pievienojot % (piemēram, ozol%), tiks meklēti nosaukumi, kas sākas ar meklēto nosaukuma daļu: Ozolkalni, Ozoliņi u.tml.
Nosaukuma daļas sākumā pievienojot % (piemēram, %ozoli), tiks meklēti nosaukumi, kas beidzas ar meklēto nosaukuma daļu: Mazozoli, Dižozoli u.tml.

Šos simbolus iespējams arī kombinēt (piemēram, ierakstot Dru_%a, būs iespējams atlasīt vietvārdus kuros pēc burta u un pirms burta a ir vismaz viens simbols: Druva, Druviena, Druja u.tml).`;


export default function Search() {

    const { onObjectIDSearch, onObjectNameSearch, onObjectTypeSearch, onClearSearch, onObjectCountrySearch, onObjectMunicipalitySearch, setCountryID, countryData, municipalityData, onFetchButtonClick, onCheckboxChange, checkboxes, checkboxOptions, municipalityOptions, uniqueTypes, isLoading, searchValues, showAlert, setShowAlert, showAlertFor3Seconds, searchVisibility, idFieldActive } = useGlobalContext();

    const areInputsEmpty = () => {
        const inputValues = {
            objectID: searchValues.objectID,
            objectName: searchValues.objectName,
            objectType: searchValues.objectType,
            objectCountry: searchValues.objectCountry,
            objectMunicipality: searchValues.objectMunicipality,
        };

        const isEmpty = Object.values(inputValues).every((value) => {
            if (value === "Visi" || value === "nav norādīts" || value === "Visas adm. vienības" || value === 1) {
                return true;
            }
            return false;
        });

        if (isEmpty) {
            // If inputs are empty, show the alert for 3 seconds
            setShowAlert(true)
            showAlertFor3Seconds();

        }
        else {
            setShowAlert(false)
        }

        return isEmpty;
    };

    const countryDataWithDefault = [{
        value: 0,
        label: "Visi",
    }, ...countryData];

    const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

    }



    return (
        <div className={`search grid-item${searchVisibility ? ' visible' : ''}`}>
            <form action="" onSubmit={onFormSubmit}>
                <h2 className='heading-secondary'>Meklēt</h2>

                <div className='search__form'>
                    <TextInput label='Nosaukuma daļa' id='nosaukums' onInputChange={onObjectNameSearch} value={searchValues.objectName} disabled={!idFieldActive} />
                    <TextInput label='Objekta ID' type="text" id='objektaid' onInputChange={onObjectIDSearch} value={searchValues.objectID !== 0 ? searchValues.objectID : ''} />

                    <DropdownInput label='Objekta veids' id='veids' onInputChange={onObjectTypeSearch} options={uniqueTypes} inputvalue={searchValues.objectType} disabled={!idFieldActive} />

                    <DropdownInput
                        label='Latviešu vēsturiskā zeme'
                        id='robeza'
                        options={countryDataWithDefault}
                        onInputChange={onObjectCountrySearch}
                        inputvalue={searchValues.objectCountry}
                        disabled={!idFieldActive}
                    />

                    <DropdownInput label='Novads, pagasts' id='vieta' options={municipalityOptions} onInputChange={onObjectMunicipalitySearch} inputvalue={searchValues.objectMunicipality} disabled={!idFieldActive} />

                    <div className='search__parent-container'>
                        <div className='search__checkbox-container'>
                            {checkboxOptions.map((option: any) => (
                                <div className='search__checkbox-label' key={option.name} >
                                    <label className="custom-checkbox" htmlFor={option.name}>
                                        <input
                                            type="checkbox"
                                            checked={checkboxes[option.name]}
                                            onChange={(event) => onCheckboxChange(option.name, event.target.checked)}
                                            disabled={!idFieldActive}

                                        />
                                        <span
                                            //@ts-ignore
                                            className="checkbox-checkmark" disabled={!idFieldActive} >
                                            {checkboxes[option.name] ? <LuCheck /> : null}
                                        </span>
                                        <div className="checkbox-text">

                                            {option.label}
                                        </div>
                                    </label>
                                </div>
                            ))}
                        </div>
                        <div className="search__help-button">
                            <TooltipWindow text={tooltipContent} icon={<LuHelpCircle />}>

                            </TooltipWindow>
                        </div>
                        {showAlert && (
                            <div className='search__alert-container'>
                                <AlertWindow text={"Nav atrasts neviens objekts!"}></AlertWindow>
                            </div>
                        )}
                    </div>

                    <div className='search__buttons'>
                        <button className='search__buttons-erase' type="button" title="Notīrīt meklētāju" onClick={onClearSearch}><LuEraser /></button>
                        <button className='search__buttons-submit' title='Meklēt' type="submit" onClick={() => areInputsEmpty() ? showAlertFor3Seconds() : onFetchButtonClick()} disabled={isLoading}>
                            {isLoading ? <PulseLoader color="#ffffff" size={6} /> : 'Meklēt'}
                        </button>
                    </div>
                </div>
            </form >
        </div >
    )
}