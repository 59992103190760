// @ts-nocheck
import React from 'react';

type DropdownInputProps = {
    label?: string;
    options?: any;
    onInputChange?: React.ChangeEventHandler<HTMLSelectElement>;
    key?: string;
    value?: string;
    id?: string;
    type?: string;
    inputvalue?: React.ChangeEventHandler<HTMLSelectElement>;
    disabled?: boolean;
}

export default function DropdownInput({ label = '', options = [], onInputChange, key, value, type, inputvalue, disabled }: DropdownInputProps) {
    const dropdownOptions = options.map((item: any) => (
        <option key={item.value} value={item.value}>
            {item.label}
        </option>
    ));

    return (
        <div className='search__input-container'>
            <label className='search__input-label'>
                {label}
            </label>
            <select className='search__input-text' onChange={onInputChange} value={inputvalue} disabled={disabled}>
                {dropdownOptions}
            </select>
        </div>
    );
}